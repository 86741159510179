import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["otherInput", "select"]
  static values = {
    otherId: String
  }

  connect() {
    // Store the original field name when connecting
    this.fieldName = this.selectTarget.name
    this.toggleOther()
  }

  toggleOther() {
    const isOther = this.selectTarget.value === this.otherIdValue
    this.otherInputTarget.classList.toggle("hidden", !isOther)

    // Toggle the name attributes to control which field is submitted
    if (isOther) {
      this.otherInputTarget.name = this.fieldName
      this.selectTarget.name = `${this.fieldName}_original`
    } else {
      this.selectTarget.name = this.fieldName
      this.otherInputTarget.name = ""
    }
  }
}
